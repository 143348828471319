//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-919:_1360,_7900,_1772,_9695,_7152,_1056,_9212,_7936;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-919')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-919', "_1360,_7900,_1772,_9695,_7152,_1056,_9212,_7936");
        }
      }catch (ex) {
        console.error(ex);
      }